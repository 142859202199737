<div class="tw-rounded tw-border tw-border-solid tw-border-warning-600 tw-bg-background">
  <div class="tw-bg-warning-600 tw-px-5 tw-py-2.5 tw-font-bold tw-uppercase tw-text-contrast">
    <i class="bwi bwi-envelope bwi-fw" aria-hidden="true"></i> {{ "verifyEmail" | i18n }}
  </div>
  <div class="tw-p-5">
    <p>{{ "verifyEmailDesc" | i18n }}</p>
    <button id="sendBtn" bitButton type="button" block [bitAction]="send">
      {{ "sendEmail" | i18n }}
    </button>
  </div>
</div>
