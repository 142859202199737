<app-header>
  <ng-container slot="title-suffix" *ngIf="loading || actionInProgress">
    <i
      class="bwi bwi-spinner bwi-spin text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
</app-header>

<p>
  {{ "deviceApprovalsDesc" | i18n }}
</p>

<bit-table [dataSource]="tableDataSource">
  <ng-container header>
    <tr>
      <th bitCell>{{ "member" | i18n }}</th>
      <th bitCell>{{ "deviceInfo" | i18n }}</th>
      <th bitCell>{{ "time" | i18n }}</th>
      <th bitCell class="tw-w-10">
        <button
          [bitMenuTriggerFor]="headerMenu"
          bitIconButton="bwi-ellipsis-v"
          size="small"
          type="button"
          appA11yTitle="{{ 'options' | i18n }}"
        ></button>
        <bit-menu #headerMenu>
          <button
            type="button"
            bitMenuItem
            (click)="denyAllRequests()"
            [disabled]="actionInProgress"
          >
            <span class="tw-text-danger">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "denyAllRequests" | i18n }}
            </span>
          </button>
        </bit-menu>
      </th>
    </tr>
  </ng-container>
  <ng-template body let-rows$>
    <tr bitRow alignContent="top" *ngFor="let r of rows$ | async">
      <td bitCell class="tw-flex-col">
        <div>{{ r.email }}</div>
        <code class="tw-text-sm">{{ r.publicKey | fingerprint: r.email | async }}</code>
      </td>
      <td bitCell class="tw-flex-col">
        <div>{{ r.requestDeviceType }}</div>
        <div class="tw-text-sm">{{ r.requestIpAddress }}</div>
      </td>
      <td bitCell class="tw-flex-col tw-text-muted">
        {{ r.creationDate | date: "medium" }}
      </td>
      <td bitCell class="tw-align-middle">
        <button
          [bitMenuTriggerFor]="rowMenu"
          bitIconButton="bwi-ellipsis-v"
          size="small"
          type="button"
          appA11yTitle="{{ 'options' | i18n }}"
        ></button>
        <bit-menu #rowMenu>
          <button
            type="button"
            bitMenuItem
            (click)="approveRequest(r)"
            [disabled]="actionInProgress"
          >
            <i class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
            {{ "approveRequest" | i18n }}
          </button>
          <button
            type="button"
            bitMenuItem
            (click)="denyRequest(r.id)"
            [disabled]="actionInProgress"
          >
            <span class="tw-text-danger">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "denyRequest" | i18n }}
            </span>
          </button>
        </bit-menu>
      </td>
    </tr>
  </ng-template>
</bit-table>

<bit-no-items
  class="tw-text-main"
  *ngIf="!loading && tableDataSource.data.length == 0"
  [icon]="Devices"
>
  <ng-container slot="title">{{ "noDeviceRequests" | i18n }}</ng-container>
  <ng-container slot="description">{{ "noDeviceRequestsDesc" | i18n }}</ng-container>
</bit-no-items>
