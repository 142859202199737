<app-header></app-header>

<bit-container>
  <p>{{ "exposedPasswordsReportDesc" | i18n }}</p>
  <button type="submit" buttonType="primary" bitButton [loading]="loading" (click)="load()">
    {{ "checkExposedPasswords" | i18n }}
  </button>
  <div class="mt-4" *ngIf="hasLoaded">
    <app-callout type="success" title="{{ 'goodNews' | i18n }}" *ngIf="!ciphers.length">
      {{ "noExposedPasswords" | i18n }}
    </app-callout>
    <ng-container *ngIf="ciphers.length">
      <app-callout type="danger" title="{{ 'exposedPasswordsFound' | i18n }}" [useAlertRole]="true">
        {{ "exposedPasswordsFoundDesc" | i18n: (ciphers.length | number) }}
      </app-callout>
      <table class="table table-hover table-list table-ciphers">
        <tbody>
          <tr *ngFor="let c of ciphers">
            <td class="table-list-icon">
              <app-vault-icon [cipher]="c"></app-vault-icon>
            </td>
            <td class="reduced-lh wrap">
              <ng-container *ngIf="!organization || canManageCipher(c); else cantManage">
                <a
                  href="#"
                  appStopClick
                  (click)="selectCipher(c)"
                  title="{{ 'editItem' | i18n }}"
                  >{{ c.name }}</a
                >
              </ng-container>
              <ng-template #cantManage>
                <span>{{ c.name }}</span>
              </ng-template>
              <ng-container *ngIf="!organization && c.organizationId">
                <i
                  class="bwi bwi-collection"
                  appStopProp
                  title="{{ 'shared' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "shared" | i18n }}</span>
              </ng-container>
              <ng-container *ngIf="c.hasAttachments">
                <i
                  class="bwi bwi-paperclip"
                  appStopProp
                  title="{{ 'attachments' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "attachments" | i18n }}</span>
              </ng-container>
              <br />
              <small>{{ c.subTitle }}</small>
            </td>
            <td>
              <app-org-badge
                *ngIf="!organization"
                [disabled]="disabled"
                [organizationId]="c.organizationId"
                [organizationName]="c.organizationId | orgNameFromId: (organizations$ | async)"
                appStopProp
              >
              </app-org-badge>
            </td>
            <td class="text-right">
              <span bitBadge variant="warning">
                {{ "exposedXTimes" | i18n: (exposedPasswordMap.get(c.id) | number) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <ng-template #cipherAddEdit></ng-template>
</bit-container>
