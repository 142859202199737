<bit-dialog [loading]="loading">
  <span bitDialogTitle>{{ "addExistingOrganization" | i18n }}</span>
  <ng-container bitDialogContent>
    <bit-table>
      <ng-template body>
        <tr bitRow *ngFor="let o of data.organizations">
          <td bitCell width="30">
            <bit-avatar [text]="o.name" [id]="o.id" size="small"></bit-avatar>
          </td>
          <td bitCell>
            {{ o.name }}
          </td>
          <td bitCell>
            <button type="button" bitButton [bitAction]="add(o)" class="tw-float-right">Add</button>
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </ng-container>
  <ng-container bitDialogFooter>
    <button type="button" bitButton bitDialogClose>
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
