<bit-dialog dialogSize="large" [loading]="loading">
  <span bitDialogTitle>
    {{ "manageSeats" | i18n }}
    <small class="tw-text-muted" *ngIf="clientName">{{ clientName }}</small>
  </span>
  <div bitDialogContent>
    <p>
      {{ "manageSeatsDescription" | i18n }}
    </p>
    <bit-form-field>
      <bit-label>
        {{ "assignedSeats" | i18n }}
      </bit-label>
      <input
        id="assignedSeats"
        type="number"
        appAutoFocus
        bitInput
        required
        [(ngModel)]="assignedSeats"
      />
    </bit-form-field>
    <ng-container *ngIf="remainingOpenSeats > 0">
      <p>
        <small class="tw-text-muted">{{ unassignedSeats }}</small>
        <small class="tw-text-muted">{{ "unassignedSeatsDescription" | i18n }}</small>
      </p>
      <p>
        <small class="tw-text-muted">{{ AdditionalSeatPurchased }}</small>
        <small class="tw-text-muted">{{ "purchaseSeatDescription" | i18n }}</small>
      </p>
    </ng-container>
  </div>
  <ng-container bitDialogFooter>
    <button
      type="submit"
      bitButton
      buttonType="primary"
      bitFormButton
      (click)="updateSubscription(assignedSeats)"
    >
      <i class="bwi bwi-refresh bwi-fw" aria-hidden="true"></i>
      {{ "save" | i18n }}
    </button>
    <button bitButton type="button" buttonType="secondary" bitDialogClose>
      {{ "cancel" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
