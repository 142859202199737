<figure>
  <div class="tw-flex tw-justify-center tw-gap-4 tw-text-[#eab308] tw-text-5xl">
    <i class="bwi bwi-star-f"></i>
    <i class="bwi bwi-star-f"></i>
    <i class="bwi bwi-star-f"></i>
    <i class="bwi bwi-star-f"></i>
    <i class="bwi bwi-star-f"></i>
  </div>
  <blockquote class="tw-mx-auto tw-my-2 tw-max-w-xl tw-px-4 tw-text-center">
    “Bitwarden scores points for being fully open-source, secure and audited annually by third-party
    cybersecurity firms, giving it a level of transparency that sets it apart from its peers.”
  </blockquote>
  <figcaption>
    <cite>
      <img
        src="../../images/register-layout/cnet-logo.svg"
        class="tw-mx-auto tw-block tw-w-40"
        alt="CNET Logo"
      />
    </cite>
    <p class="tw-text-center tw-font-bold -tw-translate-y-4">Best Password Manager in 2024</p>
  </figcaption>
</figure>
